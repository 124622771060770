import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import BlogPostSearchPage from './BlogPostSearchPage'
import useBlogPostSearchPageQuery from './useBlogPostSearchPageQuery'

const BlogPostSearchPageWithState = ({
    data,
    resolveRelations,
}) => {
    const [loadMoreOnPageEnd, setLoadMoreOnPageEnd] = useState(false)
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)
    const {
        searchResults,
        isFetching,
        isFetchingMore,
        hasMore,
        handleFetchMore,
    } = useBlogPostSearchPageQuery()
    return (
        <SbEditable content={content}>
            <BlogPostSearchPage
                {...content}
                hasMore={hasMore}
                loadMoreOnPageEnd={loadMoreOnPageEnd}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                searchResults={searchResults}
                onLoadMore={() => setLoadMoreOnPageEnd(true)}
                onFetchMore={handleFetchMore}
            />
        </SbEditable>
    )
}

export default BlogPostSearchPageWithState
