import useStoryblokQuery from '../../hooks/useStoryblokQuery'
import usePathnameLocale from '../../hooks/usePathnameLocale'

const useBlogPostPageQuery = (
    { perPage = 12, page = 1 } = {},
) => {
    const locale = usePathnameLocale()
    const params = {
        page,
        language: locale,
        per_page: perPage,
        starts_with: encodeURI('inspiratie/'),
        excluding_slugs: '_test/*,preview/*,*/hidden/*',
    }
    const {
        data,
        error,
        isLoading: isFetching,
    } = useStoryblokQuery('blog-post-search', 'cdn/stories/', params)

    let searchResults = []
    if (isFetching) {
        return {
            isFetching,
            searchResults,
        }
    }

    if (error) {
        return {
            error,
            isFetching,
            searchResults,
        }
    }

    const {
        total,
        data: {
            stories,
        },
    } = data
    searchResults = stories
    return {
        total,
        perPage,
        isFetching,
        searchResults,
    }
}

export default useBlogPostPageQuery
